<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    :label="placeholder"
    autocomplete="off"
    hide-selected
    multiple
    small-chips
    outlined
    dense
    hide-details
    single-line
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">Thêm</span>
        <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="`${item.color} lighten-3`"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">
          {{ item.text }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ index, item }">
      <v-text-field
        v-if="editing === item"
        v-model="editing.text"
        autofocus
        flat
        background-color="transparent"
        hide-details
        solo
        autocomplete="off"
        @keyup.enter="edit(index, item)"
      ></v-text-field>
      <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
        {{ item.text }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action @click.stop>
        <v-btn icon @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import { debounce } from "lodash/function";

export default {
  name: "SelectTag",
  props: {
    name: {
      type: String,
      value: () => null,
    },
    placeholder: {
      type: String,
      value: () => null,
    },
    defaultValue: {
      type: [String, Array],
      default: () => null,
    },
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    items: [{ header: "Chọn hoặc thêm mới" }],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0,
  }),
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });

      this.onChange();
    },
  },
  mounted() {
    if (this.defaultValue) {
      if (typeof this.defaultValue === "string") {
        const values = this.defaultValue.split(";");
        this.model = [...values];
      } else if (typeof this.defaultValue === "object") {
        this.model = [...this.defaultValue];
      }
    }
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },

    onChange: debounce(function () {
      const values = [...this.model].map((m) => m.text);
      this.$emit("onChange", {
        name: this.name,
        value: values,
      });
    }, 100),
  },
};
</script>

<style scoped></style>
